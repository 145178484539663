import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import ResetPasswordForm from '../components/ResetPasswordForm'
import SEO from '../components/SEO'

const ResetPassword = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Reset Password"
        description="Reset Password description goes here"
      />
      <Container>
        <PageTitle>Reset Password</PageTitle>
        <ResetPasswordForm />
      </Container>
    </Layout>
  )
}

export default ResetPassword
