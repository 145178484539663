import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import { setUser, isLoggedIn } from '../services/auth'
import { Auth } from 'aws-amplify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import ErrorsList from './ErrorsList'

const ResetPasswordForm = () => {
  if (isLoggedIn()) navigate('/account/manage')

  const [stage, setStage] = useState(0)
  const [errors, setErrors] = useState([])

  return (
    <div className="login-form-container">
      <div className="login-form">
        <div className="title">Login</div>
        <Formik
          initialValues={{ username: '', authCode: '', password: '' }}
          validate={values => {
            const errors = {}
            if (stage === 0 && !values.username) {
              errors.username = 'Required'
            } else if (
              stage === 0 &&
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)
            ) {
              errors.username = 'Invalid email address'
            }
            if (stage === 1 && !values.password) {
              errors.password = 'Required'
            }
            if (stage === 1 && !values.authCode) {
              errors.authCode = 'Required'
            }
            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            if (stage === 0) {
              try {
                await Auth.forgotPassword(values.username)
                setErrors([])
                setStage(1)
              } catch (err) {
                setErrors([err.message])
              }
            } else {
              try {
                await Auth.forgotPasswordSubmit(
                  values.username,
                  values.authCode,
                  values.password
                )
                setErrors([])
                navigate('/account/manage')
              } catch (err) {
                setErrors([err.message])
              }
            }
          }}
        >
          {({ isSubmitting }) => {
            if (stage === 0) {
              return (
                <Form>
                  <label htmlFor="username">
                    Email
                    <Field type="email" name="username" />
                    <ErrorMessage name="username" component="div" />
                  </label>
                  <button type="submit" disabled={isSubmitting}>
                    Submit
                  </button>
                </Form>
              )
            } else {
              return (
                <Form>
                  <p>
                    We sent a verification code to your email address. Please
                    enter it below to finish setting your new password.
                  </p>
                  <label htmlFor="authCode">
                    Authorization Code
                    <Field type="text" name="authCode" />
                    <ErrorMessage name="authCode" component="div" />
                  </label>
                  <label htmlFor="password">
                    New Password
                    <Field type="password" name="password" />
                    <ErrorMessage name="password" component="div" />
                  </label>
                  <button type="submit" disabled={isSubmitting}>
                    Create Account
                  </button>
                </Form>
              )
            }
          }}
        </Formik>
        <ErrorsList errors={errors} />
        <Link to="/login">{'<'} Back to login</Link>
      </div>
    </div>
  )
}

export default ResetPasswordForm
